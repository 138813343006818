module.exports = {
  tagData: {
    blog: {
      name: 'General Blog',
      path: '/blog',
      tag: 'blog',
      subtitle: 'General blog posts about life',
    },
    project: {
      name: 'Projects',
      path: '/projects',
      tag: 'project',
      subtitle: "Various things I've built",
    },

    'tech writing': {
      name: 'Tech Writing',
      path: '/tech-writing',
      tag: 'tech writing',
      subtitle: 'Some thoughts and notes related to software and technology',
    },

    //   writing: {name: 'Writing', path: '/writing'},

    idea: {
      name: 'Ideas',
      path: '/ideas',
      tag: 'idea',
      subtitle:
        'Some musings. Some serious some less-so things that maybe should exist.',
    },

    /*'book review' : {
        name: 'Book notes',
        path: '/books',
        tag: 'book review',
        subtitle:
          'Notes and thoughts from information gleaned from the greatest technology ever invented.',
      }*/
  },
}
