import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import _ from 'lodash'
import styled from 'styled-components'

import { tagData } from '../utils/tag_utils'
import Bio from '../components/Bio'
import Layout from '../components/Layout'
import colors from '../components/color'

const groupPosts = (data) => {
  console.log('data: ', data)
  const p = data.allMdx.edges
  const groups = _.groupBy(p, (i) => i.node.frontmatter.type)
  const blogPagesGroup = data.allSitePage?.edges?.filter(
    (e) => e?.node?.pageContext?.tag === 'blog'
  )
  console.log('blog pages group: ', blogPagesGroup)
  groups['blog'] = blogPagesGroup
  return groups
}

const GroupContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 auto 3rem',
  maxWidth: 800,
  justifyContent: 'space-evenly',
})

const TopicBox = styled.div`
  padding: 3rem;
  background: palevioletred;
  border-radius: 4px;
  background: ${colors.blue};
  color: #e4e4e4;
  margin-bottom: 1rem;

  @media (max-width: 560px) {
    text-align: center;
    width: 80vw;
    font-size: 2rem;
  }
`

const renderGroupBoxes = (groups) => {
  // console.log('groups: " ', groups)
  return _.map(groups, (val, k) => {
    if (k in tagData) {
      const { name, path } = tagData[k]
      return (
        <Link to={path} key={k}>
          <TopicBox>{name}</TopicBox>
        </Link>
      )
    }
  })
}

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  // const posts = data.allMarkdownRemark.edges
  const groups = groupPosts(data)

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        meta={[{ name: 'description', content: siteDescription }]}
        title={siteTitle}
      />
      <GroupContainer>{renderGroupBoxes(groups)}</GroupContainer>
      <Bio />
    </Layout>
  )
}

// todo update to use Gatsby's Head API
// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/#properties
// https://github.com/LekoArts/gatsby-starter-portfolio-jodie/blob/master/gatsby-ssr.js
// export function Head({ data: { site: { siteMetadata }}}) {
//   return (
//     <>
//       <title>{siteMetadata.title}</title>
//     </>
//   )
// }

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allSitePage {
      edges {
        node {
          id
          path
          pageContext
        }
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
          }
        }
      }
    }
  }
`

export const MdxIndexFragment = graphql`
  fragment MdxIndexFragment on MdxConnection {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          type
        }
      }
    }
  }
`

export const MetaDataFragment = graphql`
  fragment MetadataFragment on Query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
